<template>
  <div id="scraper-page">
    <b-card no-body class="h-100">
      <!-- header component -->
      <b-card-header>
        <!-- title -->
        <b-card-title class="d-flex align-items-center">
          <i class="bi bi-globe"></i>
          <span style="margin-left: 5px"> Log Scraper Tier 1 </span>
        </b-card-title>
      </b-card-header>
      <!-- body component -->
      <b-card-body class="d-flex align-items-center pb-0">
        <!-- hour filter -->
        <select
          v-model="hour_range"
          id="hour_range"
          class="form-control ml-auto"
          @change="getData()"
          style="width: 10rem"
        >
          <option value="3">3 Jam</option>
          <option value="6">6 Jam</option>
          <option value="24">1 Hari</option>
          <option value="168">7 Hari</option>
        </select>
        <!-- keyword filter -->
        <b-input-group class="input-group-merge ml-1" style="width: 20rem">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            placeholder="Masukkan kata kunci..."
            v-model="table_data.filter"
          />
        </b-input-group>
      </b-card-body>
      <!-- loader component -->
      <loader-component v-if="is_loading" />
      <!-- table component -->
      <table-component
        v-else
        :data="table_data"
        :is_row_custom_color="true"
        class="mt-1"
      />
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import useJwt from "@/auth/jwt/useJwt";
import dataTableClientPagination from "@/views/components/dataTableClientPagination.vue";
import loader from "@/views/components/loader.vue";
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BInputGroup,
  BFormGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
} from "bootstrap-vue";

export default {
  name: "LogScraperTier1",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardSubTitle,
    BInputGroup,
    BFormGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    "table-component": dataTableClientPagination,
    "loader-component": loader,
  },
  data() {
    return {
      is_loading: true,
      hour_range: 6,
      table_data: {
        filter: "",
        fields: [
          {
            key: "no",
            label: "no",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "1rem" },
          },
          {
            key: "_id",
            label: "nama scraper",
            sortable: true,
            thClass: "text-left",
            tdClass: "text-left",
          },
          {
            key: "start",
            label: "tanggal berjalan",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { paddingRight: "2rem" },
          },
          {
            key: "end",
            label: "tanggal terhenti",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { paddingRight: "2rem" },
          },
          {
            key: "duration",
            label: "durasi",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { paddingRight: "2rem" },
          },
          {
            key: "count",
            label: "jumlah",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { paddingRight: "2rem" },
          },
        ],
        items: [],
      },
      CancelToken: null,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      if (this.CancelToken) {
        this.CancelToken.cancel();
      }
      this.CancelToken = axios.CancelToken.source();
      this.is_loading = true;
      useJwt.axiosIns
        .get("scraper/log-streams?hour_range=" + this.hour_range)
        .then((res) => {
          this.table_data.items = res.data.scraper_data || [];
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          if (this.table_data.items) {
            this.is_loading = false;
          }
        });
    },
  },
};
</script>
