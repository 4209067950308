import moment from "moment";

function dateParser(date) {
  return moment(date).format("YYYY-MM-DD HH:mm:ss");
}
function dateConvertUTC7(date) {
  var utcDate = new Date(date);
  var utcTime = utcDate.getTime();
  var offset = 7 * 60 * 60 * 1000;
  var utcPlus7Time = utcTime + offset;
  var utcPlus7Date = new Date(utcPlus7Time);

  return utcPlus7Date;
}
export default {
  dateParser,
  dateConvertUTC7,
};
